import React from 'react';

interface IDotProps {
  variant: 'water-hot' | 'water-cold' | 'heat';
}

export const Dot = ({ variant }: IDotProps) => {
  return (
    <span
      style={{
        display: 'inline-block',
        borderRadius: '50%',
        backgroundColor: variant === 'heat' ? '#FF8622' : variant === 'water-cold' ? 'red' : '#025491',
        width: '14px',
        height: '14px',
        marginRight: '10px',
      }}
    ></span>
  );
};
