import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { IconButton, Typography, Box } from '@mui/material';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useFormContext } from 'react-hook-form';
import { SetStateAction, useMemo } from 'react';

dayjs.locale('pl');

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, false, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: React.Dispatch<SetStateAction<Dayjs | null>>;
}

function ButtonField(props: ButtonFieldProps) {
  const { setOpen, InputProps: { ref } = {}, value, setValue: setDate } = props;
  const { watch, setValue } = useFormContext();
  const selectedRangeType = watch('rangeType');

  const endDate = useMemo(() => {
    switch (selectedRangeType) {
      case 'Weekly':
        if (value) {
          return value.add(1, 'week');
        }
        return dayjs().add(1, 'week');
      case 'Monthly':
        if (value) {
          return value.add(1, 'month');
        }
        return dayjs().add(1, 'month');
      case 'HalfYearly':
        if (value) {
          return value.add(6, 'months');
        }
        return dayjs().add(6, 'months');
      case 'Yearly':
        if (value) {
          return value.add(1, 'year');
        }
        return dayjs().add(1, 'year');
      default:
        return dayjs().add(1, 'week');
    }
  }, [value, selectedRangeType]);

  const handleArrowBack = () => {
    if (selectedRangeType === 'Weekly') {
      if (value) {
        setDate(value.subtract(1, 'week'));
        setValue('rangeStartingPoint', value?.subtract(1, 'week').format('YYYY-MM-DD'));
      }
    }
    if (selectedRangeType === 'Monthly') {
      if (value) {
        setDate(value?.subtract(1, 'month'));
        setValue('rangeStartingPoint', value?.subtract(1, 'month').format('YYYY-MM-DD'));
      }
    }
    if (selectedRangeType === 'HalfYearly') {
      if (value) {
        setDate(value?.subtract(6, 'months'));
        setValue('rangeStartingPoint', value?.subtract(6, 'months').format('YYYY-MM-DD'));
      }
    }
    if (selectedRangeType === 'Yearly') {
      if (value) {
        setDate(value?.subtract(1, 'year'));
        setValue('rangeStartingPoint', value?.subtract(1, 'year').format('YYYY-MM-DD'));
      }
    }
  };

  const handleArrowNext = () => {
    if (selectedRangeType === 'Weekly') {
      if (value) {
        setDate(value.add(1, 'week'));
        setValue('rangeStartingPoint', value?.add(1, 'week').format('YYYY-MM-DD'));
      }
    }
    if (selectedRangeType === 'Monthly') {
      if (value) {
        setDate(value?.add(1, 'month'));
        setValue('rangeStartingPoint', value?.add(1, 'month').format('YYYY-MM-DD'));
      }
    }
    if (selectedRangeType === 'HalfYearly') {
      if (value) {
        setDate(value?.add(6, 'months'));
        setValue('rangeStartingPoint', value?.add(6, 'months').format('YYYY-MM-DD'));
      }
    }
    if (selectedRangeType === 'Yearly') {
      if (value) {
        setDate(value?.add(1, 'year'));
        setValue('rangeStartingPoint', value?.add(1, 'year').format('YYYY-MM-DD'));
      }
    }
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="row" gap={1} alignSelf="flex-end" sx={{ cursor: 'pointer' }}>
      <IconButton sx={{ color: 'black' }} size="small" onClick={handleArrowBack}>
        <ArrowBackRoundedIcon />
      </IconButton>
      <Typography sx={{ color: 'black' }} ref={ref} onClick={() => setOpen?.((prev) => !prev)}>
        {value?.locale('pl').format('DD MMMM YYYY')} {' - '}
        {endDate.locale('pl').format('DD MMMM YYYY')}
      </Typography>
      <IconButton sx={{ color: 'black' }} size="small" onClick={handleArrowNext}>
        <ArrowForwardRoundedIcon />
      </IconButton>
    </Box>
  );
}

export function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> & {
    setValue: React.Dispatch<SetStateAction<Dayjs | null>>;
  }
) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{
        ...props.slots,
        field: ButtonField,
      }}
      slotProps={{ ...props.slotProps, field: { setOpen, setValue: props.setValue } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}
