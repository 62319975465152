import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: '16px',
    margin: '16px 0',
  },
  active: {
    backgroundColor: 'black',
    color: 'white',
    border: '1px solid black',
    ':hover': {
      color: 'black',
      backgroundColor: alpha('#000000', 0.08),
    },
  },
  button: {
    color: 'black',
    '&.MuiButton-outlined': {
      border: '1px solid black',
    },
    '&.MuiButtonGroup-firstButton': {
      color: 'white',
      border: '1px solid black',
    },
    '&.MuiButtonGroup-firstButton:hover': {
      border: '1px solid black',
      color: 'black',
    },
    ':hover': {
      color: 'black',
      backgroundColor: alpha('#000000', 0.12),
    },
  },
}));
