import { useQuery } from 'react-query';

import { getDailyMeasurements, TLocationMetersDataParams } from '../infrastructure/get-daily-measurements';

export const useDailyMeasurementsQuery = ({ locationId, rangeStartingPoint, rangeType }: TLocationMetersDataParams) => {
  return useQuery({
    queryFn: () => getDailyMeasurements({ locationId, rangeStartingPoint, rangeType }),
    queryKey: ['daily-measurements-query', locationId, rangeStartingPoint, rangeType],
    cacheTime: 0,
    staleTime: 0,
  });
};
