import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Grid, Divider, Link, useMediaQuery, useTheme, Menu, MenuItem } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { User } from '@components';
import { imageSet } from '@assets';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { SnackbarContext, UserContext } from '@contexts';
import { useGetAssignedLocations, logout } from '@api';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { LOCATOR_PATH } from '@router';

import { useStyles } from './locator-dashboard.styles';

export const LocatorDashboard: React.FC = () => {
  const { classes } = useStyles();
  const { userState, userDispatch } = useContext(UserContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { data } = useGetAssignedLocations();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const logoutUser = useCallback(() => {
    logout({ userDispatch, snackbarDispatch, navigate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const navigateToFlatList = () => {
    navigate(`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.MAIN}`);
    handleClose();
  };

  const navigateToSettings = () => {
    navigate(`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.SETTINGS}`);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderFlatList = useMemo(() => {
    return data && data?.data?.results?.length > 1;
  }, [data]);

  return (
    <Grid container direction="column">
      <Grid item pr={!isMobile ? 8 : 0} pl={!isMobile ? 8 : 0}>
        <Box className={classes.headerContainer}>
          <div className={classes.leftContainer} onClick={navigateToFlatList}>
            <img alt="wodmar logo" width="123" src={imageSet.wodmarLogo} />
          </div>
          {isMobile ? (
            <div className={classes.rightContainer} onClick={handleOpenMenu}>
              <MenuRoundedIcon sx={{ fontSize: 36 }} />
            </div>
          ) : (
            <div className={classes.rightContainer}>
              {renderFlatList && (
                <Link className={classes.flatList} href={`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.MAIN}`}>
                  Lista mieszkań
                </Link>
              )}
              <Divider orientation="vertical" />
              <div style={{ cursor: 'pointer' }} onClick={handleOpenMenu}>
                <User name={userState.name} surname={userState.surname} />
              </div>
            </div>
          )}
        </Box>
      </Grid>
      <Divider />
      <Grid item xs pr={isMobile ? 2 : 8} pl={isMobile ? 2 : 8} pt={4} pb={4}>
        <Outlet />
      </Grid>
      <Menu
        className={classes.menu}
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isMobile && (
          <MenuItem disabled={true} className={classes.menuItem} onClick={handleClose}>
            <User name={userState.name} surname={userState.surname} />
          </MenuItem>
        )}
        <MenuItem className={classes.menuItem} onClick={navigateToSettings}>
          <SettingsOutlinedIcon />
          Ustawienia konta
        </MenuItem>
        {renderFlatList && isMobile && (
          <MenuItem className={classes.menuItem} onClick={navigateToFlatList}>
            <BusinessOutlinedIcon />
            Lista mieszkań
          </MenuItem>
        )}
        <MenuItem className={classes.menuItem} onClick={logoutUser}>
          <ExitToAppRoundedIcon />
          Wyloguj
        </MenuItem>
      </Menu>
    </Grid>
  );
};
