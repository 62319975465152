import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button, ButtonGroup, Paper, FormControl, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDailyMeasurementsQuery } from '@api';

import { useStyles } from './chart-wrapper.styles';
import { chartSchema, TChartFormData } from './schema';
import { CheckboxRows } from './checkbox-rows/checkbox-rows.component';
import { ButtonDatePicker } from './date-picker/date-picker.componet';
import { useSelectedHeatMetersMeasurement, useSelectedWaterMetersMeasurement } from './use-selected-measurement';
import { PlotComponent } from './plotly/plot';

interface IChartWrapperProps {
  locationId: string;
  devices: Array<{ id: string; name: string; variant?: 'hot' | 'cold' | undefined }>;
  variant: 'water-hot' | 'water-cold' | 'heat';
}

dayjs.locale('pl');

export const ChartWrapper: React.FC<IChartWrapperProps> = ({ locationId, devices, variant }: IChartWrapperProps) => {
  const { cx, classes } = useStyles();
  const [value, setValue] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const form = useForm<TChartFormData>({
    resolver: zodResolver(chartSchema),
    mode: 'onTouched',
    defaultValues: {
      rangeType: 'Monthly',
      locationId: locationId,
      devices: devices,
      rangeStartingPoint: value?.format('YYYY-MM-DD'),
    },
  });

  const { data } = useDailyMeasurementsQuery({
    locationId: locationId,
    rangeType: form.watch('rangeType'),
    rangeStartingPoint: form.watch('rangeStartingPoint'),
  });

  const { chartData, layout } = useSelectedHeatMetersMeasurement({
    serialNumbers: form.watch('devices').map((device) => device.name),
    data: data?.data && data?.data,
  });

  const { chartData: chartDataWater, layout: layoutWater } = useSelectedWaterMetersMeasurement({
    serialNumbers: form.watch('devices').map((device) => device.name),
    data: data?.data && data?.data,
  });

  return (
    <>
      <Typography variant="subtitle1">
        {variant.includes('water') ? 'Wykres zużycia wody' : 'Wykres zużycia energii cieplnej'}
      </Typography>
      <Paper className={classes.paper} elevation={3}>
        <FormProvider {...form}>
          <form>
            <Box
              display={'flex'}
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent={isMobile ? 'center' : 'space-between'}
              alignItems={'center'}
              padding={'0 50px'}
            >
              <FormControl fullWidth margin="normal" sx={isMobile ? { alignItems: 'center' } : {}}>
                <Controller
                  name="rangeType"
                  control={form.control}
                  defaultValue="Weekly"
                  render={({ field }) => (
                    <ButtonGroup variant="outlined">
                      <Button
                        size="small"
                        onClick={() => field.onChange('Weekly')}
                        className={cx(classes.button, { [classes.active]: field.value === 'Weekly' })}
                        disableElevation
                      >
                        1T
                      </Button>
                      <Button
                        size="small"
                        onClick={() => field.onChange('Monthly')}
                        className={cx(classes.button, { [classes.active]: field.value === 'Monthly' })}
                        disableElevation
                      >
                        1M
                      </Button>
                      <Button
                        size="small"
                        onClick={() => field.onChange('HalfYearly')}
                        className={cx(classes.button, { [classes.active]: field.value === 'HalfYearly' })}
                        disableElevation
                      >
                        6M
                      </Button>
                      <Button
                        size="small"
                        onClick={() => field.onChange('Yearly')}
                        className={cx(classes.button, { [classes.active]: field.value === 'Yearly' })}
                        disableElevation
                      >
                        1Y
                      </Button>
                    </ButtonGroup>
                  )}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ButtonDatePicker
                    label={value == null ? null : value.format('MM/DD/YYYY')}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    setValue={setValue}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <FormControl fullWidth margin="normal">
              <CheckboxRows devices={devices} variant={variant} />
            </FormControl>
          </form>
        </FormProvider>
        {variant.includes('water') && layoutWater && chartDataWater && (
          <PlotComponent data={chartDataWater} layout={layoutWater} config={{ displayModeBar: false }} />
        )}
        {variant.includes('heat') && layout && chartData && (
          <PlotComponent data={chartData} layout={layout} config={{ displayModeBar: false }} />
        )}
      </Paper>
    </>
  );
};
