import React from 'react';
import { Box, Checkbox, FormControlLabel, useMediaQuery, useTheme } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { Dot } from './dot';

interface IDevice {
  id: string;
  name: string;
  variant?: 'hot' | 'cold';
}

interface ICheckboxRowsProps {
  devices: Array<IDevice>;
  variant: 'water-hot' | 'water-cold' | 'heat';
}

export const CheckboxRows = ({ devices, variant }: ICheckboxRowsProps) => {
  const { watch, setValue } = useFormContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedDevices = watch('devices');

  const selectedDevicesIds = selectedDevices.map((device: IDevice) => device.id);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        maxHeight={150}
        flexWrap={isMobile ? 'nowrap' : 'wrap'}
        overflow="auto"
        padding={'0 50px'}
      >
        {devices.map((device: IDevice) => (
          <FormControlLabel
            key={device.id}
            control={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={selectedDevicesIds.includes(device.id)}
                  sx={{
                    color: 'black',
                    '&.Mui-checked': {
                      color: 'black',
                    },
                  }}
                />
                <Dot variant={variant} />
              </div>
            }
            label={
              variant === 'water-hot' || variant === 'water-cold'
                ? `Wodomierz [${device.name}] ${device.variant === 'cold' ? '(woda zimna)' : '(woda ciepła)'}`
                : `Ciepłomierz [${device.name}]`
            }
            onClick={() => {
              setValue(
                'devices',
                selectedDevicesIds.includes(device.id)
                  ? selectedDevices.filter((d: IDevice) => d.id !== device.id)
                  : [...selectedDevices, device]
              );
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
