import { Params, useParams } from 'react-router-dom';
import { useGetLocationMetersData, useGetLocationOverview } from '@api';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import { Tab, Tabs } from '@mui/material';
import { useMemo, useState } from 'react';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';

import { useStyles } from './flat-view.styles';
import { ConsumptionCard } from './consumption-card/consumption-card.component';
import { ReportForm } from './report-form/report-form.component';
import { MeasurementDetails } from './data-grid/data-grid.component';
import { ChartWrapper } from './chart/chart-wrapper';

enum FILTERS_TAB {
  ALL,
  WATER_METER,
  HEAT_METER,
  ALERTS,
}

const replaceAddressOrder = (address: string) => {
  const addressArray = address.split(',');
  const firstWord = addressArray.shift();
  return [...addressArray, firstWord].join(', ');
};

export const FlatView = () => {
  const { classes } = useStyles();
  const params = useParams<Params>();
  const { flatId } = params;
  const { data, isError } = useGetLocationOverview({ locationId: flatId ?? '' });
  const [filter, setFilter] = useState<FILTERS_TAB>(FILTERS_TAB.ALL);
  const [isChartVisible, setChartVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setFilter(newValue);
  };

  const flatAddress = data?.data.address ?? '';

  const measuredUtility = useMemo(() => {
    switch (filter) {
      case FILTERS_TAB.WATER_METER:
        return 'Water';
      case FILTERS_TAB.HEAT_METER:
        return 'Heat';
      default:
        return undefined;
    }
  }, [filter]);

  const onlyAlerts = useMemo(() => {
    switch (filter) {
      case FILTERS_TAB.ALL:
        return undefined;
      case FILTERS_TAB.WATER_METER:
        return undefined;
      case FILTERS_TAB.HEAT_METER:
        return undefined;
      case FILTERS_TAB.ALERTS:
        return true;
      default:
        return undefined;
    }
  }, [filter]);

  const { data: heatMeters, isSuccess: heatMetersReady } = useGetLocationMetersData({
    measuredUtility: 'Heat',
    onlyAlerts,
    locationId: flatId ?? '',
  });
  const { data: waterMeters, isSuccess: waterMetersReady } = useGetLocationMetersData({
    measuredUtility: 'Water',
    onlyAlerts,
    locationId: flatId ?? '',
  });

  const devicesHeatMeters = useMemo(() => {
    return heatMeters?.data.map((meter) => ({
      id: meter.id,
      name: meter.serialNumber,
    }));
  }, [heatMeters]);

  const devicesWaterMeters = useMemo(() => {
    return waterMeters?.data.map((meter) => ({
      id: meter.id,
      name: meter.serialNumber,
      variant: meter.measuredUtility === 'ColdWater' ? 'cold' : ('hot' as 'cold' | 'hot' | undefined),
    }));
  }, [waterMeters]);

  if (isError || !flatId) {
    return (
      <div>
        <Typography variant="h3">Nie znaleziono mieszkania</Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography variant="h3">ul. {replaceAddressOrder(flatAddress)}</Typography>
      <div className={classes.flatView}>
        <div className={classes.container}>
          <ConsumptionCard type={'cold-water'} consumption={data?.data.coldWaterMetersReadingSum ?? 0} />
          <ConsumptionCard type={'hot-water'} consumption={data?.data.hotWaterMetersReadingSum ?? 0} />
          <ConsumptionCard type={'heater'} consumption={data?.data.heatMetersReadingSum ?? 0} />
        </div>
        <div className={classes.reportContainer}>
          <ReportForm locationId={flatId} />
        </div>
      </div>
      <div className={classes.dataGrid}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <Typography variant="h3">Szczegóły pomiarów</Typography>
            <Typography variant="body1">
              {isChartVisible ? 'Wykresy przedstawiają sumaryczne przyrosty dla zaznaczonych liczników' : ''}
            </Typography>
          </div>
          <div>
            {!isMobile && (
              <Button
                variant="outlined"
                color="info"
                startIcon={<BarChartOutlinedIcon />}
                size="small"
                onClick={() => {
                  setChartVisible((isChartVisible) => !isChartVisible);
                }}
              >
                {isChartVisible ? 'Widok tabeli' : 'Widok wykresów'}
              </Button>
            )}
          </div>
        </div>
        <div style={{ marginTop: 24 }}>
          {isChartVisible && waterMetersReady && heatMetersReady ? (
            <div style={{ paddingBottom: '10px' }}>
              <ChartWrapper variant="water-hot" locationId={flatId} devices={devicesWaterMeters || []} />
              <ChartWrapper variant="heat" locationId={flatId} devices={devicesHeatMeters || []} />
            </div>
          ) : (
            <div>
              <Tabs value={filter} onChange={handleChange} aria-label="icon position tabs example">
                <Tab icon={<GridViewOutlinedIcon />} iconPosition="start" label="Wszystkie" />
                <Tab icon={<WaterDropOutlinedIcon />} iconPosition="start" label="Wodomierze" />
                <Tab icon={<WaterOutlinedIcon className={classes.icon} />} iconPosition="start" label="Ciepłomierze" />
                <Tab icon={<ErrorOutlineOutlinedIcon />} iconPosition="start" label="Wykryte błędy" />
              </Tabs>
              <div style={{ marginTop: 8 }}>
                <MeasurementDetails locationId={flatId} measuredUtility={measuredUtility} onlyAlerts={onlyAlerts} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
