import { DataGrid } from '@mui/x-data-grid';
import { CustomNoRowsOverlay } from '@components';
import { useGetLocationMetersData } from '@api';
import { LOCATOR_PATH } from '@router';
import { useNavigate } from 'react-router-dom';

import { MeasurementDetailsColumns } from './columns';

interface ILocationMetersDataParams {
  measuredUtility?: 'Water' | 'Heat';
  onlyAlerts?: boolean;
  locationId: string;
}

export const MeasurementDetails = ({
  locationId,
  measuredUtility,
  onlyAlerts,
}: ILocationMetersDataParams): JSX.Element => {
  const { data } = useGetLocationMetersData({ measuredUtility, onlyAlerts, locationId });
  const navigate = useNavigate();

  window.onpopstate = () => {
    navigate(`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.FLAT_VIEW.replace(':flatId', locationId)}`);
  };

  const navigateToDetails = (id: string, type: 'water' | 'heater') => {
    navigate(`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.DEVICE.replace(':id', id).replace(':type', type)}`, {
      replace: false,
    });
  };

  return (
    <DataGrid
      rows={data?.data || []}
      initialState={{
        pagination: {
          pageSize: 10,
          page: 0,
        },
      }}
      sx={{
        '& .MuiDataGrid-row': {},
      }}
      hideFooterSelectedRowCount={true}
      headerHeight={50}
      rowBuffer={0}
      rowHeight={50}
      columnBuffer={0}
      columns={MeasurementDetailsColumns({ navigateToDetails })}
      showColumnRightBorder={true}
      showCellRightBorder={true}
      autoHeight={true}
      disableColumnMenu={true}
      disableColumnFilter={true}
      checkboxSelection={false}
      hideFooter={true}
      disableSelectionOnClick
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
    />
  );
};
