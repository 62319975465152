import React from 'react';
import Plot from 'react-plotly.js';
import Plotly, { Config, Data, Layout } from 'plotly.js';
import * as locale from 'plotly.js-locales/pl';

export interface IPlotComponentProps {
  data: Partial<Data>[];
  layout: Partial<Layout>;
  config: Partial<Config>;
  handleRelayout?: (e: Plotly.PlotRelayoutEvent) => void;
  handleHover?: (event: Plotly.PlotHoverEvent) => void;
  handleUnhover?: () => void;
  height?: number;
}

export const PlotComponent = React.memo(
  ({ data, layout, config, handleRelayout, handleHover, handleUnhover, height }: IPlotComponentProps) => {
    return (
      <Plot
        data={data}
        layout={layout}
        config={{
          ...config,
          locale: 'pl',
          locales: { pl: locale },
        }}
        useResizeHandler={true}
        style={{ width: '100%', height }}
        onRelayout={handleRelayout}
        onHover={handleHover}
        onUnhover={handleUnhover}
      />
    );
  }
);
