import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';
import { z } from 'zod';

const deltaSchema = z.object({
  value: z.number(),
  fromDate: z.string().nullable(),
  toDate: z.string().nullable(),
});

const meterSchema = z.object({
  meterId: z.string().uuid(),
  utility: z.enum(['Heat', 'ColdWater', 'HotWater']),
  serialNumber: z.string(),
  deltas: z.array(deltaSchema),
});

const responseSchema = z.object({
  hotWaterMetersDailyDeltas: z.array(meterSchema),
  coldWaterMetersDailyDeltas: z.array(meterSchema),
  heatMetersDailyDeltas: z.array(meterSchema),
  heatMaxValue: z.number(),
  waterMaxValue: z.number(),
});

const locationMetersDataParamsSchema = z.object({
  locationId: z.string(),
  rangeStartingPoint: z.string(),
  rangeType: z.enum(['Weekly', 'Monthly', 'HalfYearly', 'Yearly']),
});

export type TDailyMeasurementsResponse = z.infer<typeof responseSchema>;
export type TLocationMetersDataParams = z.infer<typeof locationMetersDataParamsSchema>;

export const getDailyMeasurements = ({
  locationId,
  rangeType,
  rangeStartingPoint,
}: TLocationMetersDataParams): Promise<AxiosResponse<TDailyMeasurementsResponse>> => {
  return httpsService.get(
    Endpoint.LOCATOR_DAILY_MEASUREMENTS.replace(':locationId', locationId)
      .replace(':rangeType', rangeType || '')
      .replace(':rangeStartingPoint', rangeStartingPoint || '')
  );
};
