import { useMemo } from 'react';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';

import hotWaterIcon from './icon-hot-water.svg';
import { useStyles } from './consumption-card.styles';
import hotWater from './hot-water.png';
import coldWater from './cold-water.png';
import heater from './heater.png';
import { Typography } from '@mui/material';

interface IConsumptionCardProps {
  type: 'cold-water' | 'hot-water' | 'heater';
  consumption: number;
}

export const ConsumptionCard = ({ type, consumption }: IConsumptionCardProps) => {
  const { classes } = useStyles();

  const renderCard = useMemo(() => {
    switch (type) {
      case 'cold-water':
        return (
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <WaterDropOutlinedIcon />
              <Typography variant={'h6'}> Woda Zimna </Typography>
            </div>
            <div className={classes.cardBody}>
              <img src={coldWater} alt="cold-water" width={128} />
              <Typography variant="h5">{consumption} m³</Typography>
            </div>
            <Typography className={classes.text} variant="body1">
              Stan licznika
            </Typography>
          </div>
        );
      case 'hot-water':
        return (
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <img src={hotWaterIcon} alt="hot-water-icon" />
              <Typography variant={'h6'}> Woda Ciepła </Typography>
            </div>
            <div className={classes.cardBody}>
              <img src={hotWater} alt="hot-water" width={128} />
              <Typography variant="h5">{consumption} m³</Typography>
            </div>
            <Typography className={classes.text} variant="body1">
              Stan licznika
            </Typography>
          </div>
        );
      case 'heater':
        return (
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <WaterOutlinedIcon className={classes.icon} />
              <Typography variant={'h6'}>Energia cieplna </Typography>
            </div>
            <div className={classes.cardBody}>
              <img src={heater} alt="heater-water" width={128} />
              <Typography variant="h5">{consumption} GJ</Typography>
            </div>
            <Typography className={classes.text} variant="body1">
              Stan licznika
            </Typography>
          </div>
        );
      default:
        return <p>Unknown </p>;
    }
  }, [type, classes.icon, classes.cardHeader, classes.text, consumption, classes.card, classes.cardBody]);

  return <div className={classes.container}>{renderCard}</div>;
};
