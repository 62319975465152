import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { PATH } from '@router';
import { useContext } from 'react';
import { DeviceContext } from '@contexts';
import { capitalizeFirstLetter } from '@helpers';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import StyledBreadcrumb from './breadcrumbs.styles';
import { SkeletonLoader } from '@components';

const DeviceBreadCrumb = () => {
  const {
    device: { name, type, installationAddress },
  } = useContext(DeviceContext);

  if (!!name.length || !!type.length) {
    return (
      <Typography variant="subtitle2" color="text.primary">
        {installationAddress} {'/'} {capitalizeFirstLetter(type)} {name}
      </Typography>
    );
  }

  return <SkeletonLoader loadersCount={1} />;
};

const Breadcrumbs = () => {
  const routes = [
    {
      path: `/${PATH.DASHBOARD}`,
      breadcrumb: null,
    },
    {
      path: `/${PATH.DASHBOARD}/${PATH.PANEL}`,
      breadcrumb: 'Podgląd odczytów',
    },
    {
      path: `${PATH.DASHBOARD}/zarzadzanie/:id`,
      breadcrumb: null,
    },
    {
      path: `${PATH.DASHBOARD}/zarzadzanie/:id/:type`,
      breadcrumb: DeviceBreadCrumb,
    },
    {
      path: `${PATH.DASHBOARD}/zarzadzanie/:id/:type/${PATH.DEVICE_DETAILS_OVERVIEW}`,
      breadcrumb: 'Podsumowanie',
    },
    {
      path: `/${PATH.DASHBOARD}/${PATH.REPORTS}/${PATH.MONTHLY_REPORT}`,
      breadcrumb: 'Miesięczny',
    },
    {
      path: `/${PATH.DASHBOARD}/${PATH.ADMIN_LIST}`,
      breadcrumb: 'Lista administratorów systemu',
    },
    {
      path: `/${PATH.DASHBOARD}/${PATH.CLIENTS_LIST}`,
      breadcrumb: 'Administratorzy',
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes);
  breadcrumbs?.shift();

  return (
    <MuiBreadcrumbs sx={{ mt: 2, mb: 2, ml: 5 }} aria-label="breadcrumb">
      {breadcrumbs?.map(({ match, breadcrumb }, i) => (
        <StyledBreadcrumb key={match.pathname} to={match.pathname}>
          {breadcrumb}
        </StyledBreadcrumb>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
