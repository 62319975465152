import { TDailyMeasurementsResponse } from '@api';
import { Data, Layout } from 'plotly.js';
import { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';

interface ISelectedMeasurement {
  serialNumbers: string[];
  data?: TDailyMeasurementsResponse;
}

interface Delta {
  value: number;
  fromDate: string | null;
  toDate: string | null;
}

interface IMeter {
  meterId: string;
  utility: string;
  serialNumber: string;
  deltas: Delta[];
}

interface IResult {
  chartData: Partial<Data>[];
  layout: Partial<Layout>;
}

export const useSelectedHeatMetersMeasurement = ({ serialNumbers, data }: ISelectedMeasurement): IResult => {
  const theme = useTheme();
  const selectedHeatMeters = useMemo(() => {
    return data?.heatMetersDailyDeltas.filter((meter) => serialNumbers.includes(meter.serialNumber));
  }, [serialNumbers, data]);

  const groupAndSumByFromDate = useCallback((meters: IMeter[]) => {
    if (!meters) return [];

    return meters.reduce((acc: Record<string, number>, meter) => {
      meter.deltas.forEach((delta: Delta) => {
        if (!delta.fromDate) return;
        if (!acc[delta.fromDate]) {
          acc[delta.fromDate] = 0;
        }
        if (delta.value > 0) {
          acc[delta.fromDate] += delta.value;
        }
      });
      return acc;
    }, {});
  }, []);

  const chartData: Partial<Data>[] = useMemo(() => {
    if (!selectedHeatMeters) return [];

    const groupedMeasurementsFromHeatMeters = groupAndSumByFromDate(selectedHeatMeters);
    const xaxis = Object.keys(groupedMeasurementsFromHeatMeters);
    const yaxis = Object.values(groupedMeasurementsFromHeatMeters);
    return [
      {
        x: xaxis,
        y: yaxis,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'Ciepłomierze',
        marker: { color: '#FF8622' },
      },
    ];
  }, [selectedHeatMeters, groupAndSumByFromDate]);

  const maxYValue = useMemo(() => {
    if (!selectedHeatMeters) return 0;
    const yaxis = Object.values(groupAndSumByFromDate(selectedHeatMeters));

    return Math.max(...yaxis);
  }, [groupAndSumByFromDate, selectedHeatMeters]);

  const layout: Partial<Layout> = useMemo(
    () => ({
      height: 500,
      paper_bgcolor: theme.palette.background.paper,
      plot_bgcolor: theme.palette.background.paper,
      autosize: true,
      hovermode: 'closest',
      showlegend: false,
      xaxis: {
        type: 'date',
        autorange: true,
        visible: true,
        showgrid: true,
        title: {
          text: 'Data',
        },
      },
      margin: {
        t: 40,
        b: 40,
        l: 100,
        r: 100,
      },
      dragmode: 'zoom',
      yaxis: {
        type: 'linear',
        showgrid: true,
        visible: true,
        range: [-0.5, maxYValue + 1],
        autorange: maxYValue < 0 && 'max',
        title: {
          text: 'Przyrosty energii cieplnej [GJ]',
        },
      },
    }),
    [theme.palette.background.paper, maxYValue]
  );

  return {
    chartData,
    layout,
  };
};

export const useSelectedWaterMetersMeasurement = ({ serialNumbers, data }: ISelectedMeasurement): IResult => {
  const theme = useTheme();
  const selectedColdWaterMeters = useMemo(() => {
    return data?.coldWaterMetersDailyDeltas.filter((meter) => serialNumbers.includes(meter.serialNumber));
  }, [serialNumbers, data]);

  const selectedHotWaterMeters = useMemo(() => {
    return data?.hotWaterMetersDailyDeltas.filter((meter) => serialNumbers.includes(meter.serialNumber));
  }, [serialNumbers, data]);

  const groupAndSumByFromDate = useCallback((meters: IMeter[]) => {
    if (!meters) return [];

    return meters.reduce((acc: Record<string, number>, meter) => {
      meter.deltas.forEach((delta: Delta) => {
        if (!delta.fromDate) return;
        if (!acc[delta.fromDate]) {
          acc[delta.fromDate] = 0;
        }
        if (delta.value > 0) {
          acc[delta.fromDate] += delta.value;
        }
      });
      return acc;
    }, {});
  }, []);

  const chartData: Partial<Data>[] = useMemo(() => {
    const data: Partial<Data>[] = [];
    if (selectedColdWaterMeters) {
      const groupedMeasurementsFromColdWaterMeters = groupAndSumByFromDate(selectedColdWaterMeters);
      const xaxisColdWater = Object.keys(groupedMeasurementsFromColdWaterMeters);
      const yaxisColdWater = Object.values(groupedMeasurementsFromColdWaterMeters);
      data.push({
        x: xaxisColdWater,
        y: yaxisColdWater,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'Wodomierz - zimna woda',
        marker: { color: '#025491' },
      });
    }

    if (selectedHotWaterMeters) {
      const groupedMeasurementsFromHotWaterMeters = groupAndSumByFromDate(selectedHotWaterMeters);
      const xaxisHotWater = Object.keys(groupedMeasurementsFromHotWaterMeters);
      const yaxisHodWater = Object.values(groupedMeasurementsFromHotWaterMeters);
      data.push({
        x: xaxisHotWater,
        y: yaxisHodWater,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'Wodomierz - ciepła woda',
        marker: { color: 'red' },
      });
    }

    return data;
  }, [selectedColdWaterMeters, selectedHotWaterMeters, groupAndSumByFromDate]);

  const maxYValue = useMemo(() => {
    if (!selectedColdWaterMeters) return 0;
    const yaxisColdWaterMeters = Object.values(groupAndSumByFromDate(selectedColdWaterMeters));
    if (!selectedHotWaterMeters) return 0;
    const yaxisHotWaterMeters = Object.values(groupAndSumByFromDate(selectedHotWaterMeters));
    return Math.max(...yaxisColdWaterMeters, ...yaxisHotWaterMeters);
  }, [groupAndSumByFromDate, selectedColdWaterMeters, selectedHotWaterMeters]);

  const layout: Partial<Layout> = useMemo(
    () => ({
      height: 500,
      paper_bgcolor: theme.palette.background.paper,
      plot_bgcolor: theme.palette.background.paper,
      autosize: true,
      hovermode: 'closest',
      showlegend: false,
      xaxis: {
        type: 'date',
        autorange: true,
        visible: true,
        showgrid: true,
        title: {
          text: 'Data',
        },
      },
      margin: {
        t: 40,
        b: 40,
        l: 100,
        r: 100,
      },
      dragmode: 'zoom',
      yaxis: {
        type: 'linear',
        showgrid: true,
        visible: true,
        range: [-0.5, maxYValue + 1],
        title: {
          text: 'Zużycie wody [m³]',
        },
      },
    }),
    [theme.palette.background.paper, maxYValue]
  );

  return {
    chartData,
    layout,
  };
};
